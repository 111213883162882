<template>
	<div class="mainTem">
		<!-- <div class="buyerHeader">
			<h3 class="buyerHeaderTit">
				SKU拆分
			</h3>
		</div> -->
		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<span class="tct_tit">SKU<span>{{$t('i18nn_b83011b3bfea8797')}}</span></span>
						<!-- <el-tooltip effect="dark" :content="$t('btn.long_Export_excel')"ng_Export_excel')" placement="top">
							<el-button type="warning" circle icon="el-icon-download" size="mini"
								@click="exportExcelAction()"></el-button>
						</el-tooltip> -->
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">
					<!-- <ul class="actionConList"> -->
						<!-- <li> -->
							<el-button type="success" @click="openDioalog(null)" size="small" icon="el-icon-plus">{{$t('i18nn_52687a58ca0645c3')}}</el-button>
						<!-- </li> -->
					<!-- </ul> -->
					
					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button>
				</el-col>
			</el-row>
		</div>
		<!-- <div class="formMsgCon">
        <h3><i class="titleIcon el-icon-bell"></i>{{$t('i18nn_41413cddfa61538c')}}</h3>
        <div class="formMsgDet">
          <p>
            此为录入仓库的SKU数据
          </p>
        </div>
    </div> -->
		<div class="filterCon" style="">
			<div class="filterBarList">
					<el-menu :default-active="filterData.status" mode="horizontal" @select="handleSelectStatus">
					  <el-menu-item :index="item.value" v-for="item in selectOption.statusList" :key="item.value">
							<span>
								<!-- {{ item.label }} -->
								{{i18nFormatter(item.value)}}
							</span>
						</el-menu-item>
					</el-menu>
			</div>
			<div class="filterAction">
				<!-- <ul class="actionConList">
					<li>
						<el-button type="success" @click="openDioalog(null)" size="small" icon="el-icon-plus">{{$t('i18nn_52687a58ca0645c3')}}</el-button>
					</li>
				</ul> -->
				<ul class="filterConList">
					<li>
						<span>{{ $t('i18nn_f77f473d27f48bdc') }}</span>
						<SelAgentUser @change="changeAgentUser"></SelAgentUser>
					</li>
					<li>
						<span>{{$t('i18nn_c944a6686d996ab3')}}</span>
						<whNoSelect @changeData="changWhNo2"></whNoSelect>
					</li>
					<li>
						<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
						<el-input :placeholder="$t('i18nn_3ee4c9b76289e93a')" size="small" v-model="filterData.keyword"
							maxlength="50" :clearable="true" @keyup.enter.native="initData()" style="width: 250px;"></el-input>
					</li>
					
					<li>
						<el-button icon="el-icon-search" size="small" type="primary" @click="initData()"></el-button>
					</li>
					<!-- <li>
						<el-button icon="el-icon-s-operation" size="small" type="primary" @click="openMoreFilter()"></el-button>
					</li> -->
				</ul>
			</div>
		</div>
		
		<!-- <div class="wh-filter-con">
			<div class="">
			  <span>{{$t('Storage.packing_list.status')}}</span>
				<el-radio-group v-model="filterData.status" size="medium" @change="serPageData()">
				  <el-radio-button border v-for="item in selectOption.statusList" :key="item.value" :label="item.value">
						{{item.label}}
					</el-radio-button>
				</el-radio-group>
			</div>
		</div> -->
		<!-- <div class="wh-filter-con">
			<div class="wh-filter-item">
				<span>{{ $t('i18nn_f77f473d27f48bdc') }}</span>
				<SelAgentUser @change="changeAgentUser"></SelAgentUser>
			</div>
			<div class="wh-filter-item">
				<span>{{$t('i18nn_c944a6686d996ab3')}}</span>
				<whNoSelect @changeData="changWhNo2"></whNoSelect>
			</div>
			
			<div class="wh-filter-item">
				<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
				<el-input :placeholder="$t('i18nn_3ee4c9b76289e93a')" size="small" v-model="filterData.keyword" maxlength="50" :clearable="true" style="width: 150px;"></el-input>
			</div>
			<el-button type="primary" @click="serPageData" size="small">{{ $t('FormMsg.Search') }}</el-button>
		</div> -->

		<!-- <div class="wh-action-con">
			<el-button type="success" @click="openDioalog(null)" size="small" icon="el-icon-plus">{{$t('i18nn_52687a58ca0645c3')}}</el-button>
		</div> -->

		<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
			<el-table id="ex_table" ref="multipleTable" :data="tableData" stripe :border="true" style="width: 100%" size="small" :max-height="$store.state.frameConHeightWh1">
				<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center" :label="$t('Storage.tableColumn.no')"></el-table-column>
				<!-- <el-table-column type="selection" fixed="left" align="center" width="50" label-class-name="nodra"></el-table-column> -->

				<!-- "id":"数据ID",
    "goodsSubjectId":"类目",
    "goodsSku":"SKU编码",
    "goodsName":"SKU名称",
    "goodsNameEn":"SKU英文标题",
    "goodsImg":"SKU图片",
    "declarePrice":"价值",
    "goodsWeight":"重量",
    "sizeUnit":"尺寸单位",
    "goodsLength":"长",
    "goodsWidth":"宽",
    "goodsHeight":"高",
    "safeStock":"安全库存"-->

				<el-table-column prop="statusName" :label="$t('Storage.packing_list.status')">
					<template slot-scope="scope">
						<el-tag type="" v-if="'0' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="success" v-else-if="'10' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="warning" v-else-if="'20' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="danger" v-else-if="'60' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
					</template>
				</el-table-column>
				<!-- <el-table-column prop="cusName" :label="$t('i18nn_29991afa9781d554')"></el-table-column> -->
				<el-table-column prop="whNo" :label="$t('i18nn_c944a6686d996ab3')"></el-table-column>
				<!-- <el-table-column prop="subjectName" :label="$t('Storage.skuInfo.category')"></el-table-column> -->
				
				<el-table-column prop="goodsSku" label="原SKU"></el-table-column>
				<el-table-column prop="quantity" :label="$t('i18nn_788d9bdcf8ff660e')"></el-table-column>
				
				<el-table-column prop="recordLists" :label="'分拆后SKU*数量'" min-width="200">
					<template slot-scope="scope">
						<div v-if="scope.row.recordLists && scope.row.recordLists.length>0">
							<div v-for="(item,index) in scope.row.recordLists" :key="index">
								{{item.goodsSku}} * <strong>{{item.breakQ}}</strong>
							</div>
						</div>
					</template>
				</el-table-column>

				<!-- <el-table-column prop="goodsSku" :label="'SKU CODE'" width="400px">
					<template slot-scope="scope">
						<barcode :code="scope.row.goodsSku"></barcode>
					</template>
				</el-table-column> -->

				<!-- <el-table-column prop="goodsName" :label="$t('Storage.skuInfo.Chinese_title')"></el-table-column> -->

				<!-- <el-table-column prop="goodsNameEn" :label="$t('Storage.skuInfo.English_title')"></el-table-column> -->

				<!-- <el-table-column prop="goodsImg" :label="$t('Storage.skuInfo.SKU_pictures')">
					<template slot-scope="scope">
						<div>
							<el-image lazy style="width: auto; height: 40px" fit="contain" :src="scope.row.goodsImg" @click="openBigImg($event, scope.row.goodsImg)">
								<div slot="error" class="image-slot" style="font-size: 40px;"><i class="el-icon-picture-outline"></i></div>
							</el-image>
						</div>
					</template>
				</el-table-column> -->

				<!-- <el-table-column prop="sizeUnitName" :label="$t('Storage.skuInfo.unit')"></el-table-column> -->

				<!-- <el-table-column prop="goodsLength" :label="$t('Storage.skuInfo.length')"></el-table-column> -->
				<el-table-column prop="breakNo" :label="$t('i18nn_9700e172aec17e89')"></el-table-column>
				<el-table-column prop="commitDate" :label="$t('i18nn_0ae081c3b8c4d4a1')"></el-table-column>

				<!-- <el-table-column prop="goodsWeight" :label="$t('Storage.skuInfo.weight')"></el-table-column>
				<el-table-column prop="declarePrice" :label="$t('Storage.skuInfo.price')"></el-table-column>
				<el-table-column prop="safeStock" :label="$t('Storage.skuInfo.safety_stock')"></el-table-column> -->

				<!-- <el-table-column prop="remark" :label="$t('Storage.tableColumn.remark')"></el-table-column> -->

				<el-table-column :label="$t('Storage.tableColumn.operation')" width="200px" fixed="right">
					<template slot-scope="scope">
						<!-- <div v-if="isSel">
							<el-button @click="selRow($event, scope.row, scope.$index)" type="warning" size="mini" icon="el-icon-magic-stick">{{ $t('FormMsg.Select') }}</el-button>
						</div> -->
						<div>
							<div v-if="0==scope.row.status">
								<el-button @click="openEdit($event, scope.row, scope.$index)" type="warning" size="mini" icon="el-icon-edit">{{ $t('FormMsg.Edit') }}</el-button>
								<el-button @click="delAction($event, scope.row)" type="danger" size="mini" icon="el-icon-minus">{{ $t('FormMsg.Delete') }}</el-button>
							</div>
							<div style="margin-top: 10px;">
								<el-button v-if="0==scope.row.status" @click="submitlAction($event, scope.row)" type="success" size="mini" icon="el-icon-position">{{$t('i18nn_ad4345dbaabe1479')}}</el-button>
								
								<el-button v-if="10==scope.row.status" @click="cancelAction($event, scope.row)" type="danger" size="mini" icon="el-icon-close">{{$t('i18nn_ce6f905c8859cf74')}}</el-button>
							</div>
						</div>
						
					</template>
				</el-table-column>
			</el-table>

			<div class="tableCon_pagination"><hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination></div>
		</div>
		<!-- SKU拆分 -->
		<!-- <el-dialog :close-on-click-modal="false" top="10px" append-to-body :visible.sync="dialogFormVisible" custom-class="myFullDialog4"> -->
		<el-drawer :wrapperClosable="false" append-to-body :visible.sync="dialogFormVisible" :direction="'rtl'" size="1200px">
			<template slot="title">
				<h3>SKU<span>{{$t('i18nn_b83011b3bfea8797')}}</span></h3>
			</template>
			<!-- {
			    "id":null, //"数据ID",
			    "whNo":"", //"仓库编号",
			    "goodsSku":"", //"要分拆的SKU",
			    "quantity":"", //"件数",
			    "recordLists":[
			        {
			            "goodsSku":"", //"分拆后的SKU",
			            "breakQ":"", //"预计分拆件数",
			            "id":null, //"数据ID"
			        }
			    ]
			} -->

			<el-form ref="form" :rules="formRules" :size="'small'" :model="form" label-width="150px" style="width: 1000px;" v-loading="loading" :element-loading-text="$t('i18nn_1b4525c800280581')">
				<!-- <h3 class="titSty1">{{$t('Storage.packing_list.basic_information')}}</h3> -->

				<!-- <h3 class="titSty1">{{$t('i18nn_877c5a0e44a0eb07')}}</h3> -->

				<el-form-item :label="$t('i18nn_c944a6686d996ab3')" required>
					<!-- <el-input type="text" v-model="form.whNo" style="width: 100px;" :placeholder="$t('FormMsg.Please_Enter')"></el-input> -->
					<!-- <el-select filterable :clearable="false" size="" v-model="form.whNo" :placeholder="$t('FormMsg.Please_select')" style="width: 200px;" @change="serPageData()">
						<el<el-option v-for="item in selectOption.wh_no" :key="item.code" :label="$Utils.i18nCodeText(item)" :value="item.code"><div class="sel_option_s1" style="">
				<span class="sel_option_name">{{ $Utils.i18nCodeText(item) }}</span>
				<span class="sel_option_code" style="">{{ item.code }}</span>
			</div></el-option>			</el-select> -->
			<whNoSelect ref="whNoSelect" @changeData="changWhNo"></whNoSelect>
				</el-form-item>

				<el-card>
					<div slot="header"><span><span>{{$t('i18nn_3a6fdc59b1cd84c1')}}</span>SKU</span></div>
					<div>
						<el-row>
							<el-col :span="8">
								<el-form-item label="SKU" required>
									<!-- <el-input type="text" v-model="form.goodsSku" style="width: 200px;" :placeholder="$t('FormMsg.Please_Enter')"></el-input> -->
									<!-- <el-button size="mini" type="primary" icon="el-icon-link" @click="openMainSelData()"><span>{{$t('i18nn_7fb6ada66a1fccca')}}</span>SKU</el-button> -->
									<el-button size="mini" type="primary" icon="el-icon-link" @click="selSkuInv()"><span>{{$t('i18nn_7fb6ada66a1fccca')}}</span>SKU</el-button>
									
									<!-- <el-input type="text" size="small" v-model="form.goodsSku"></el-input> -->
									<div>{{ form.goodsSku }}</div>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item :label="$t('i18nn_3775920d44088b96')" required><el-input-number v-model="form.quantity"></el-input-number></el-form-item>
							</el-col>
						</el-row>
					</div>
				</el-card>

				<div style="font-size: 20px; line-height: 200%;padding: 5px 0; text-align: center;"><i class="el-icon-bottom"></i></div>
				<!-- <h3 class="titSty1">规格信息(体积)</h3> -->
				<!-- <el-form-item :label="$t('Storage.skuInfo.unit')" prop="sizeUnit">
					<el-select filterable clearable size="mini" v-model="form.sizeUnit" style="width: 200px;" :placeholder="$t('FormMsg.Please_select')">
						<el-option v-for="item in selectOption.wh_size_unit" :key="item.code" :label="$Utils.i18nCodeText(item)" :value="item.code"></el-option>
					</el-select>
				</el-form-item> -->
				<el-card>
					<div slot="header"><span><span>{{$t('i18nn_6726a8914c9083a3')}}</span>SKU</span></div>
					<div>
						<el-button type="primary" size="small" style="margin-bottom: 10px;" icon="el-icon-plus" @click="addSplit">{{$t('i18nn_24c8f46012a25c89')}}</el-button>
						<el-table :data="form.recordLists" stripe :border="true" size="small">
							<!-- <el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center" :label="$t('Storage.tableColumn.no')"></el-table-column> -->
							<el-table-column prop="goodsSku" :label="'SKU'">
								<template slot-scope="scope">
									<div>
										<!-- <el-input type="text" size="small" v-model="scope.row.goodsSku" style="width: 200px;" :placeholder="$t('FormMsg.Please_Enter')"></el-input> -->
										<el-button size="mini" type="primary" icon="el-icon-link" @click="openSelData($event, scope.row, scope.$index)">选择SKU</el-button>
										<!-- <el-input type="text" size="small" v-model="scope.row.goodsSku"></el-input> -->
										<div>{{ scope.row.goodsSku }}</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="breakQ" :label="$t('i18nn_f9db93b87e08763b')">
								<template slot-scope="scope">
									<div><el-input-number size="small" v-model="scope.row.breakQ"></el-input-number></div>
								</template>
							</el-table-column>
							<el-table-column :label="$t('Storage.tableColumn.operation')" width="120px" fixed="right">
								<template slot-scope="scope">
									<div>
										<el-button @click="delSplit($event, scope.row)" type="danger" size="mini" icon="el-icon-minus">{{ $t('FormMsg.Delete') }}</el-button>
									</div>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</el-card>

				<!-- <el-row>
					<el-col :span="8">
						<el-form-item :label="'分拆后的SKU'" prop="">
							<el-input type="text" v-model="form.goodsSku" style="width: 200px;" :placeholder="$t('FormMsg.Please_Enter')"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="件数：" prop="">
							<el-input-number v-model="form.breakQ" style="width: 200px;"></el-input-number>
						</el-form-item>
					</el-col>
				</el-row> -->

				<!-- <h3 class="titSty1">{{$t('i18nn_004617dc47191cb8')}}</h3> -->

				<!-- <h3 class="titSty1">{{$t('Storage.packing_list.Other')}}</h3> -->
				<!-- <el-form-item :label="$t('Storage.tableColumn.remark')">
					<el-input type="textarea" :placeholder="$t('FormMsg.Please_Enter')" v-model="form.remark" maxlength="" show-word-limit style="width: 300px;"></el-input>
				</el-form-item> -->
			</el-form>
			<!-- <div>
        <el-button type="primary" style="width: 220px;" @click="submitForm('form', '3')" v-if="!!form.id">{{$t('i18nn_d552aa9f1c14363f')}}</el-button>
        <el-button type="primary" style="width: 220px;" @click="submitForm('form', '1')" v-else>{{$t('i18nn_0ddb67d8d6d01ad4')}}</el-button>
      </div> -->
			<div class="drawer-footer">
				<!-- <el-button type="primary" plain @click="dialogFormVisible = false">{{ $t('FormMsg.Close') }}</el-button> -->
				<el-button type="primary" icon="el-icon-tickets" @click="submitForm('form')">{{ $t('FormMsg.Save') }}</el-button>
			</div>
		</el-drawer>
		<!-- </el-dialog> -->

		<!-- 导入excel -->
		<!-- <el-dialog
			:title="$t('Storage.skuInfo.Import_SKU')"
			append-to-body
			:close-on-click-modal="false"
			:visible.sync="dialogUploadVisible"
			width="1200px"
			top="0"
			v-loading="loadingExcel"
		>
			<div>
				<input
					style="display: none;"
					type="file"
					id=""
					ref="file"
					@change="fileChange($event)"
					:accept="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'"
				/>
				<el-button type="success" size="small" icon="el-icon-plus" @click="openExcel">{{ $t('module.upload.Select_Excel') }}</el-button>
				<span style="display: inline-block; width: 200px;">{{ excelFileName }}</span>
				<el-button type="primary" size="small" icon="el-icon-upload" @click="uploadExcel">{{ $t('module.upload.Upload_preview') }}</el-button>
				<span style="padding-left: 100px;">
					<el-link
						:href="'https://huyi-oss-wh.oss-cn-hangzhou.aliyuncs.com/newModel/skuImportMod.xlsx?random=' + Math.random()"
						target="_blank"
						type="primary"
						icon="el-icon-download"
					>
						{{ $t('module.upload.Download_template') }}
					</el-link>
				</span>
			</div>
			<div style="overflow: auto; width: 100%; height: 80%;">
				<el-table :data="excelData" stripe :border="true" style="width: 100%" size="small">
					<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center" :label="$t('Storage.tableColumn.no')"></el-table-column>
					<el-table-column prop="goodsSku" label="SKU"></el-table-column>
					<el-table-column prop="goodsName" :label="$t('Storage.skuInfo.Chinese_title')"></el-table-column>
					<el-table-column prop="goodsNameEn" :label="$t('Storage.skuInfo.English_title')"></el-table-column>
					<el-table-column prop="sizeUnitName" :label="$t('Storage.skuInfo.unit')"></el-table-column>

					<el-table-column prop="goodsLength" :label="$t('Storage.skuInfo.length')"></el-table-column>
					<el-table-column prop="goodsWidth" :label="$t('Storage.skuInfo.width')"></el-table-column>
					<el-table-column prop="goodsHeight" :label="$t('Storage.skuInfo.height')"></el-table-column>
					<el-table-column prop="goodsWeight" :label="$t('Storage.skuInfo.weight')"></el-table-column>

					<el-table-column prop="declarePrice" :label="$t('Storage.skuInfo.price')"></el-table-column>

					<el-table-column prop="remark" :label="$t('Storage.tableColumn.remark')"></el-table-column>

				</el-table>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogUploadVisible = false">{{ $t('FormMsg.Close') }}</el-button>
				<el-button type="primary" @click="submitExcelForm()">{{ $t('FormMsg.Save') }}</el-button>
			</div>
		</el-dialog> -->

		<!--查看图片-->
		<!-- <el-dialog :title="$t('FormMsg.picture')" append-to-body :close-on-click-modal="false" :visible.sync="dialogImgVisible" width="1000px" top="0">
			<div style="overflow: auto; width: 100%; height: 80%;"><img :src="imgUrlBigShow" width="auto" height="auto" /></div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogImgVisible = false">{{ $t('FormMsg.Close') }}</el-button>
			</div>
		</el-dialog> -->
		<!--选择sku-->
		<!-- <el-dialog :title="$t('FormMsg.Select')" append-to-body :close-on-click-modal="false" :visible.sync="dialogSelVisible" width="1200px" top="0"> -->
		<el-drawer :wrapperClosable="false" :title="$t('FormMsg.Select')" append-to-body :visible.sync="dialogSelVisible" :direction="'rtl'" size="1200px">
			<div style=""><WSkuInfo :isSel="true" :status="''" @selectRow="selWhGoodsData"></WSkuInfo></div>
			<!-- <div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogSelVisible = false">{{ $t('FormMsg.Close') }}</el-button>
			</div> -->
		</el-drawer>
		<!-- </el-dialog> -->
		
		<!-- 选择已有库存的SKU -->
		<dialogWSkuInventoriesSingle :openTime="skuInvOpenTime" :whNo="form.whNo" @selectRow="sureSel"></dialogWSkuInventoriesSingle>

		<!-- excel 导出排序 -->
		<!-- <whExcelCustom
			:openTime="excelOpenTime"
			:excelHead="excelHead"
			:excelData="expExcelData"
			:pagination="pagination"
			:excelName="excelName"
			:excelOption="excelOption"
			:expHttpUrl="$urlConfig.WhMyGoodsSkuPageList"
			:expHttpFilter="pageFilterData()"
		></whExcelCustom> -->
	</div>
</template>
<script>
	import {
		WSkuSplit_status
	} from '@/i18n/langStatus.js';
	import {
		i18nStatus
	} from '@/i18n/FormatI18n.js';
	
// import JQ from 'jquery';
// import CommodityCateLinkage from '@/components/Common/CommodityCateLinkage.vue';
// import excelUtilsNew from '@/utils/excelUtilsNew.js';
// import barcode from '@/components/Common/barcode.vue';
import SelAgentUser from '@/components/Common/SelAgentUser.vue';
// import whExcelCustom from '@/components/StorageCenter/components/whExcelCustom.vue';
import WSkuInfo from '@/components/StorageCenter/WSkuProduct/WSkuInfo.vue';
import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';

import dialogWSkuInventoriesSingle from '@/components/StorageCenter/WSkuProduct/dialogWSkuInventoriesSingle.vue';

export default {
	// name: 'BuyerOrder',
	//meta信息seo用
	// metaInfo: {
	//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
	//   meta: [{ // set meta
	//     name: '互易天下-买家中心-收货账单',
	//     content: '互易天下-厂家共享平台-买家中心-收货账单'
	//   }],
	//   // link: [{                 // set link
	//   //   rel: 'asstes',
	//   //   href: 'https://assets-cdn.github.com/'
	//   // }]
	// },
	// props: ['isDialog'],
	components: {
		// CommodityCateLinkage,
		// barcode,
		SelAgentUser,
		WSkuInfo,
		whNoSelect,
		dialogWSkuInventoriesSingle
		// whExcelCustom
	},
	props: {
		// mobile:"",
		isSel: {
			default: function() {
				return false;
			},
			type: Boolean
		},
		status: {
			default: function() {
				return '';
			},
			type: String
		}
	},
	data() {
		return {
			//打开SKU选择框
			dialogSelVisible: false,
			openRowData: {},
			openRowIndex: {},
			dialogSelType: '',
			//excel导出
			// excelOpenTime: '',
			// excelHead: [],
			// expExcelData: [],
			// excelName: '',
			// excelOption: {},
			// activeName: 'second',
			// isShowFrom:false,
			// pickerOptions1: {
			//     disabledDate(time) {
			//       return time.getTime() > Date.now();
			//     },
			//     shortcuts: [{
			//       text: this.$t('i18nn_0200bd47bb4bb83d'),
			//       onClick(picker) {
			//         picker.$emit('pick', new Date());
			//       }
			//     }, {
			//       text: this.$t('i18nn_84b73bfc6dada445'),
			//       onClick(picker) {
			//         const date = new Date();
			//         date.setTime(date.getTime() - 3600 * 1000 * 24);
			//         picker.$emit('pick', date);
			//       }
			//     }, {
			//       text: this.$t('i18nn_e3f3138cf5ba3f6d'),
			//       onClick(picker) {
			//         const date = new Date();
			//         date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
			//         picker.$emit('pick', date);
			//       }
			//     }]
			//   },
			UserInfo: this.$store.getters.getUserInfo,
			dialogFormVisible: false,
			dialogFormStatus: 0, //0-新增，1-修改
			
			skuInvOpenTime: '',

			//导入excel
			// dialogUploadVisible: false,
			// loadingExcel: false,
			// fileExcel: '',
			// excelData: [],
			// excelFileName: '',

			//图片放大
			// dialogImgVisible: false,
			// imgUrlBigShow: '',

			// CateValue: [],
			loading: false,
			// form: {
			// 	id: null, //"数据ID",
			// 	goodsSubjectId: '', //this.$t('i18nn_268e50d838264ca8'),
			// 	goodsSku: '', //"SKU编码",
			// 	goodsName: '', //"SKU名称",
			// 	goodsNameEn: '', //"SKU英文标题",
			// 	goodsImg: '', //"SKU图片",
			// 	declarePrice: '', //this.$t('i18nn_9db48ed0e8ec64a6'),
			// 	goodsWeight: '', //this.$t('i18nn_96f1a4b17e75892d'),
			// 	sizeUnit: '', //this.$t('i18nn_38bc71c0dc55904b'),
			// 	goodsLength: '', //this.$t('i18nn_0e60303b30d5d4b4'),
			// 	goodsWidth: '', //this.$t('i18nn_6cd31871f8528dd5'),
			// 	goodsHeight: '', //this.$t('i18nn_93c9ea4a0e52c641'),
			// 	safeStock: '' //this.$t('i18nn_004617dc47191cb8')
			// },
			form: {
				id: null, //"数据ID",
				whNo: '', //this.$t('i18nn_5acbec83efb27445'),
				goodsSku: '', //"要分拆的SKU",
				quantity: 1, //this.$t('i18nn_3775920d44088b96'),
				recordLists: [
					{
						goodsSku: '', //"分拆后的SKU",
						breakQ: '', //this.$t('i18nn_382a3d27fb8dc70a'),
						id: null //"数据ID"
					}
				]
			},
			formRules: {
				whNo: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				goodsSku: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				quantity: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }]
			},
			loading_load: false,
			tableData: [],
			//分页数据
			pagination: this.$Utils.defaultPagination(),
			selectOption: {
				// wh_no: [],
				// wh_weight_unit: [],
				// wh_vol_unit: [],
				// wh_goods_fee_type:[],
				wh_size_unit: [],
				statusList: WSkuSplit_status
			},
			//查询，排序方式
			filterData: {
				orderBy: 'id_', //排序字段
				sortAsc: 'desc', //desc降序，asc升序

				agentUser: '',
				// "accountPeriod":"",
				// "billNo":""
				status: '',
				// putWhNo: '',
				goodsSku: '',
				// hashCode: '',
				goodsName: '',
				goodsNameEn: '',
				keyword: '',
				whNo: '',
				// declareNameCh: '',
			}
		};
	},

	//创建时
	created() {
		// this.getPageData();
	},
	//编译挂载前
	mounted() {
		// this.getDicData();

		this.initData();
	},
	methods: {
		initData() {
			this.pagination.current_page = 1;
			// this.currentSel = {};
			// this.multipleSelection = [];
			this.getPageData();
			// this.getDicData();
		},
		i18nFormatter(value) {
			return i18nStatus(value, this.selectOption.statusList)
		},
		changeAgentUser(val) {
			this.filterData.agentUser = val;
			this.initData();
		},
		changWhNo2(data) {
			console.log('changWhNo', data);
			this.filterData.whNo = data.code;
			// this.filterData.userId = data.userId;
			this.initData();
		},
		changWhNo(data) {
			console.log('changWhNo', data);
			this.form.whNo = data.code;
			// this.filterData.userId = data.userId;
			// this.initData();
		},
		exportExcelAction() {
			let columns = [
				{
					title: 'SKU',
					key: 'goodsSku'
				},
				// {
				// 	title: 'SKU CODE',
				// 	key: 'goodsSkuImg',
				// 	types: 'image'
				// },
				{
					title: this.$t('Storage.skuInfo.Chinese_title'),
					key: 'goodsName'
				},
				{
					title: this.$t('Storage.skuInfo.English_title'),
					key: 'goodsNameEn'
				},
				{
					title: this.$t('Storage.skuInfo.unit'),
					key: 'sizeUnitName'
				},
				{
					title: this.$t('Storage.skuInfo.length'),
					key: 'goodsLength'
				},
				{
					title: this.$t('Storage.skuInfo.width'),
					key: 'goodsWidth'
				},
				{
					title: this.$t('Storage.skuInfo.height'),
					key: 'goodsHeight'
				},
				{
					title: this.$t('Storage.skuInfo.weight'),
					key: 'goodsWeight'
				},
				{
					title: this.$t('Storage.skuInfo.price'),
					key: 'declarePrice'
				}
				// {
				// 	title: this.$t('hytxs0000037'),
				// 	key: 'totalStock',
				// 	types: 'custom',
				// 	formatter:function(row){
				// 		if(row.placeStockTotal && row.placeStockTotal.totalStock){
				// 			return row.placeStockTotal.totalStock
				// 		} else {
				// 			return 0;
				// 		}

				// 	}
				// },
			];
			// let Data = this.tableDataCatch;
			// return columns;
			let Data = this.tableData;

			// let data_dom = JQ('#ex_table').find('.el-table__body');
			// let obj_key_img = {};
			// // console.log(data_dom.find("tr"));
			// data_dom.find('tr').each((index, domEle) => {
			// 	// console.log("index",index);
			// 	let imgObj = JQ(domEle).find('td .cell img');
			// 	// console.log("imgObj",imgObj);
			// 	if (imgObj) {
			// 		obj_key_img[imgObj.attr('code')] = imgObj.attr('src');
			// 	}
			// });
			// // console.log("obj_key_img",obj_key_img);
			// Data.forEach(item => {
			// 	item.goodsSkuImg = obj_key_img[item.goodsSku];
			// });
			// console.log('Data',Data);
			// return;
			this.expExcelData = Data;
			this.excelHead = columns;
			this.excelName = 'wh_sku';
			this.excelOption = {};
			this.excelOpenTime = new Date().getTime();
			// excelUtilsNew.exportExcel(columns, Data, 'wh_sku');
		},
		//跳转页面
		// toPageUrl(name){
		//   this.$router.push({'name':name});
		// },
		//打开新增编辑，弹窗
		openDioalog(formParm) {
			// console.log(formParm);
			this.dialogFormVisible = true;
			let form = Object.assign({}, formParm);
			console.log('form', form);
			// // 重置
			this.resetForm('form');

			if (null === formParm) {
				//新增

				// this.form.weightUnit = '1';
				// this.form.volumeUnit = '1';
				// this.form.whFeeType = '1';

				this.dialogFormStatus = 0;
				// form.subUserId = null;
				// form.userSubUserId = null;
				// form.state = true;
				//浅拷贝、对象属性的合并
				this.form = form;
				this.form.recordLists = [];
				
				this.$nextTick(()=>{
					this.$refs.whNoSelect.init(this.form.whNo);
				});
			} else {
				//修改
				this.dialogFormStatus = 1;
				// form.state = form.state === '0' ? true : false;
				// this.form.goodsSubjectId = this.CateValue[0];
				// this.CateValue = [form.goodsSubjectId];
				//浅拷贝、对象属性的合并
				this.form = form;
				this.getDetList(this.form.id);
				this.$nextTick(()=>{
					this.$refs.whNoSelect.init(this.form.whNo);
				});
			}
			// this.$alert('确定要确认通过该笔账单！', this.$t('tips.tipsTitle'), {
			//       type: 'warning',
			//       //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//     });
		},
		//打开编辑
		openEdit(event, row, index) {
			event.stopPropagation();
			this.openDioalog(row, this.$t('FormMsg.Edit'));
		},

		//删除
		delAction(event, row) {
			this.$confirm(this.$t('FormMsg.confirm_Delete'), this.$t('tips.tipsTitle'), {
				// confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
				// cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
				type: 'warning'
			})
				.then(() => {
					this.delDataAction(event, row);
				})
				.catch(() => {});
		},
		//删除
		delDataAction(event, row) {
			event.stopPropagation();
			console.log('delDataAction', row);
			// let parm = [];

			//   if (!!row) {
			//     //单条
			//     parm = [row.id];
			//   } else {
			//     //多条
			// //     let dataList = this.multipleSelection;
			// //     if (dataList.length < 1) {
			// //       this.$message.warning(this.$t('i18nn_7b80e66b535a3732'));
			// //       return;
			// //     }

			// //     let dataListParm = dataList.map(v => v.id);
			// //     parm = dataListParm;
			//     // console.log('dataListParm', dataListParm);
			//     // let dataParm = {
			//     //   ids: dataListParm
			//     // };
			//   }

			this.postData(this.$urlConfig.WhSkuSplitDel + '/' + row.id, {}, 'put');
		},

		//取消拆分
		cancelAction(event, row) {
			this.$confirm('确定取消拆分吗？', this.$t('tips.tipsTitle'), {
				// confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
				// cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
				type: 'warning'
			})
				.then(() => {
					this.postData(this.$urlConfig.WhSkuSplitCancel + '/' + row.id, {}, 'put');
				})
				.catch(() => {});
		},
		//提交
		submitlAction(event, row) {
			this.$confirm(this.$t('tips.confirmSubmit'), this.$t('tips.tipsTitle'), {
				// confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
				// cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
				type: 'warning'
			})
				.then(() => {
					this.postData(this.$urlConfig.WhSkuSplitSubmit + '/' + row.id, {}, 'put');
				})
				.catch(() => {});
		},
		// hyUpLoadImg1: function(childValue) {
		// 	// childValue就是子组件传过来的值
		// 	this.form.goodsImg = childValue;
		// },

		//查看图片
		// openBigImg(event, imgsrc) {
		// 	event.stopPropagation();
		// 	console.log('openBigImg', imgsrc);
		// 	this.dialogImgVisible = true;
		// 	this.imgUrlBigShow = imgsrc;
		// },
		//商品类目变化
		// CateChang(v) {
		// 	// this.onChangeNoSave(true);

		// 	this.CateValue = v;

		// 	this.form.goodsSubjectId = this.CateValue[0];

		// 	// this.baseInfo.commodityCategories.first = this.CateValue[0];
		// 	// this.baseInfo.commodityCategories.second = this.CateValue[1];
		// 	// this.baseInfo.commodityCategories.third = this.CateValue[2];

		// 	//该类目商品基本属性
		// 	// this.getCommodityAttribute();
		// },
		//点击状态为非出账弹出窗
		// noConfirm(){
		//   this.$alert(this.$t('i18nn_e741d17b1fd891c2')已出账this.$t('i18nn_d6f109b7bee2f33f')确认"操作，请核实！', this.$t('tips.tipsTitle'), {
		//         type: 'warning',
		//         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		//       });
		// },
		//提交信息
		submitForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					let formData = Object.assign({}, this.form);
					//浅拷贝、对象属性的合并
					if (0 === this.dialogFormStatus) {
						formData.id = null;
						// formData.userId = this.UserInfo.id;

						this.postData(this.$urlConfig.WhSkuSplitAdd, formData);
					} else {
						// formData.userId = this.UserInfo.id;
						this.postData(this.$urlConfig.WhSkuSplitAdd, formData);
					}
				} else {
					console.log('error submit!!');
					this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
					});
					return false;
				}
			});
		},
		//重置输入框
		resetForm(formName) {
			console.log(formName);
			// console.log(this.$refs[formName]);

			if (this.$refs[formName]) {
				this.$refs[formName].resetFields();
			} else {
				console.log('this.$refs[formName]', this.$refs[formName]);
			}
			// this.CateValue = [];
			// if (this.$refs.hyUpLoadImg1) {
			// 	this.$refs.hyUpLoadImg1.initUrl('');
			// }
		},

		//提交信息
		postData(url, formData, type) {
			// let _this = this;
			this.loading = true;
			this.loading_load = true;
			let HttpType = {};
			if ('delete' == type) {
				HttpType = this.$http.delete(url, formData);
			} else {
				HttpType = this.$http.put(url, formData);
			}
			// formData.state = formData.state ? '0' : '1';
			HttpType.then(({ data }) => {
				console.log(this.$t('tips.submitSuccess'));
				console.log(data);
				
				this.loading = false;
				this.loading_load = false;
				if (200 == data.code) {
					this.dialogFormVisible = false;
					this.getPageData();
					// this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
					//   type: 'success',
					//   //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
					// });
					// this.$message.success(this.$t('tips.submitSuccess'));
					if ('delete' == type) {
						this.$message({
							type: 'success',
							message: this.$t('tips.successfullyDelete')
						});
					} else {
						// this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
						// 	type: 'success'
						// });
						this.$message.success(this.$t('tips.submitSuccess'));
					}
				} else {
					// if (!data.msg) {
					//   data.msg = this.$t('tips.submitError');
					// }
					this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
					});
				}
			}).catch(error => {
				console.log(error);
				console.log(this.$t('tips.submitError'));
				this.loading = false;
				this.loading_load = false;
				this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
					type: 'warning'
					//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
				});
			});
		},
		// clearFile() {
		// 	this.fileExcel = null;
		// 	this.excelFileName = '';
		// 	// this.excelFileName = "";
		// 	this.$nextTick(() => {
		// 		try {
		// 			this.$refs.file.value = '';
		// 		} catch (e) {
		// 			console.log(e);
		// 			//TODO handle the exception
		// 		}
		// 	});
		// },

		// 新建-表格-拆分
		addSplit() {
			this.form.recordLists.push({
				goodsSku: '', //"分拆后的SKU",
				breakQ: 1 //this.$t('i18nn_382a3d27fb8dc70a'),
				// "id":"数据ID"
			});
		},
		//删除-表格-拆分
		delSplit(event, row, index) {
			this.form.recordLists.splice(index, 1);
		},
		//打开选择数据 要分拆
		// openMainSelData() {
		// 	this.dialogSelVisible = true;
		// 	this.dialogSelType = 1;
		// },
		//打开选择数据  分拆后
		openSelData(event, row, index) {
			event.stopPropagation();

			this.dialogSelVisible = true;
			// this.dialogSelType = 2;
			this.openRowData = row;
			this.openRowIndex = index;
			// this.$nextTick(() => {
			//   this.$refs.hyUpLoadImg1.openFile();
			// });
		},
		//选择数据后回调
		selWhGoodsData(selRow) {
			this.dialogSelVisible = false;
			console.log('selRow', selRow);
			// if (1 == this.dialogSelType) {
			// 	this.form.goodsSku = selRow.goodsSku;
			// } else {
				let row = this.openRowData;
				// row.putWhNo = selRow.putWhNo;
				// row.plPlaceNo = selRow.placeNo;
				row.goodsSku = selRow.goodsSku;
				// row.sendAcount = 0;
				// row.mySkuDto = selRow;

				this.$set(this.form.recordLists, this.openRowIndex, row);
			// }
		},
		
		// 选择要分拆的库存SKU
		selSkuInv() {
			if(!this.form.whNo){
				this.$message.warning(this.$t('FormMsg.Select_long_wh'))
				return;
			}
			this.skuInvOpenTime = new Date().getTime();
		},
		// 确定-选择要分拆的库存SKU
		sureSel(row){
			this.form.goodsSku = row.goodsSku;
			this.$forceUpdate();
			// val.forEach(item=>{
			// 	// let hasSku = this.form.sendRecordDtoList.some(item2=> item.goodsSku == item2.goodsSku);
			// 	//是否已经存在此SKU
			// 	// if(!hasSku){//不存在
			// 		this.form.sendRecordDtoList.push({
			// 			goodsSku: item.goodsSku,
			// 			sendAcount: undefined,
			// 			sendCTN: undefined,
			// 			ctnPCS: undefined,
			// 			shipmentCode: "",
			// 			markNo: "",
			// 		});
			// 	// }
			// })
		},

		//打开导入弹窗
		// openExcelDialog() {
		// 	this.dialogUploadVisible = true;
		// 	this.excelData = [];
		// 	this.clearFile();
		// },
		// fileChange(e) {
		// 	// this.loading = true;
		// 	console.log(e);

		// 	console.log(this.$t('i18nn_f156a9536049f461'), this.$refs.file);
		// 	this.excelData = [];
		// 	this.fileExcel = null;
		// 	this.excelFileName = '';
		// 	if (!this.$refs.file || !this.$refs.file.files) {
		// 		this.$alert(this.$t('module.upload.Unknown_file'), this.$t('tips.tipsTitle'), {
		// 			// //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		// 		});
		// 		return;
		// 	}
		// 	if (this.$refs.file.files[0]) {
		// 		// if (this.fileAccept.indexOf(this.$refs.file.files[0].type) > -1) {
		// 		if (this.$refs.file.files[0].size < 1024 * 1024 * 10) {
		// 			this.fileExcel = this.$refs.file.files[0];
		// 			this.excelFileName = this.fileExcel.name;
		// 			// console.log(this.$t('i18nn_f156a9536049f461'),this.$refs.file);
		// 			// this.uploadExcel();
		// 		} else {
		// 			this.$alert(this.$t('module.upload.uploaded_over') + '10M', this.$t('tips.tipsTitle'), {});
		// 		}
		// 		// } else {
		// 		//   this.$alert(this.$t(this.$t('i18nn_4dadf13ebce2167d')), this.$t('tips.tipsTitle'), {
		// 		//   });
		// 		// }
		// 	} else {
		// 		// this.$alert(this.$t('i18nn_c8e99e36cefd743a'), this.$t('tips.tipsTitle'), {
		// 		// });
		// 	}
		// },

		// openExcel() {
		// 	// this.initExcelData();
		// 	// this.$nextTick(()=>{
		// 	try {
		// 		this.$refs.file.value = '';
		// 	} catch (e) {
		// 		console.log(e);
		// 		//TODO handle the exception
		// 	}
		// 	// });
		// 	// this.$refs.file.dispatchEvent(new MouseEvent('click'));
		// 	var comment = this.$refs.file;
		// 	if (document.all) {
		// 		// For IE
		// 		comment.click();
		// 	} else if (document.createEvent) {
		// 		// 在ff中要为a标签添加click事件，并且侦听该事件
		// 		var ev = document.createEvent('MouseEvents');
		// 		ev.initEvent('click', false, true);
		// 		comment.dispatchEvent(ev);
		// 	} else {
		// 		// this.$alert('打开上传文件有误，请联系客服', this.$t('tips.tipsTitle'), {
		// 		this.$alert(this.$t('module.upload.open_error_img'), this.$t('tips.tipsTitle'), {
		// 			// //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		// 		});
		// 	}
		// },
		//导入excel
		// uploadExcel() {
		// 	if (!this.fileExcel) {
		// 		this.$alert(this.$t('module.upload.Please_sel_file'), this.$t('tips.tipsTitle'), {});
		// 		return;
		// 	}

		// 	this.loadingExcel = true;

		// 	let file = this.fileExcel;
		// 	var formdata = new FormData();
		// 	formdata.append('file', file);

		// 	this.$http
		// 		.post(this.$urlConfig.WhMyGoodsSkuExUpload, formdata, {
		// 			headers: {
		// 				'Content-Type': 'multipart/form-data'
		// 			}
		// 		})
		// 		.then(({ data }) => {
		// 			console.log(this.$t('tips.submitSuccess'));
		// 			console.log(data);
		// 			this.loadingExcel = false;
		// 			if (200 == data.code && data.rows) {
		// 				this.excelData = data.rows;
		// 				// if('1'==data.data.flag){
		// 				//   this.excelData = data.data.data;
		// 				// } else {
		// 				//   this.$alert(data.data.errorMsg, this.$t('tips.tipsTitle'), {
		// 				//     type: 'warning',
		// 				//     //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		// 				//   });
		// 				// }
		// 				this.clearFile();
		// 			} else {
		// 				this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
		// 					type: 'warning'
		// 					//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		// 				});
		// 			}
		// 			// if (200 == data.code && data.data) {
		// 			//   if('1'==data.data.flag){
		// 			//     this.excelData = data.data.data;
		// 			//   } else {
		// 			//     this.$alert(data.data.errorMsg, this.$t('tips.tipsTitle'), {
		// 			//       type: 'warning',
		// 			//       //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		// 			//     });
		// 			//   }

		// 			// } else {
		// 			//   this.$alert(data.msg?data.msg:this.$t("tips.submitError"), this.$t('tips.tipsTitle'), {
		// 			//     type: 'warning',
		// 			//     //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		// 			//   });
		// 			// }
		// 		})
		// 		.catch(error => {
		// 			console.log(error);
		// 			console.log(this.$t('tips.submitError'));
		// 			this.loadingExcel = false;
		// 			this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
		// 				type: 'warning'
		// 				//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		// 			});
		// 		});
		// },
		//提交-导入excel
		// submitExcelForm() {
		// 	this.loadingExcel = true;
		// 	this.$http
		// 		.put(this.$urlConfig.WhMyGoodsSkuExSubmit, this.excelData)
		// 		.then(({ data }) => {
		// 			console.log(this.$t('tips.submitSuccess'));
		// 			console.log(data);
		// 			this.loadingExcel = false;
		// 			if (200 == data.code && data.data) {
		// 				if ('1' == data.data.flag) {
		// 					// this.excelData = data.data.data;
		// 					this.dialogUploadVisible = false;
		// 					this.excelData = [];
		// 					this.excelFileName = '';
		// 					this.fileExcel = null;

		// 					this.initData();
		// 					// this.$alert(this.$t('i18nn_b93f7c8c4e2346f8'), this.$t('tips.tipsTitle'), {
		// 					//   type: 'success',
		// 					//   //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		// 					// });
		// 					this.$message.success(this.$t('tips.submitSuccess'));
		// 				} else {
		// 					this.$alert(data.data.errorMsg, this.$t('tips.tipsTitle'), {
		// 						type: 'warning'
		// 						//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		// 					});
		// 				}
		// 			} else {
		// 				this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
		// 					type: 'warning'
		// 					//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		// 				});
		// 			}
		// 		})
		// 		.catch(error => {
		// 			console.log(error);
		// 			console.log(this.$t('tips.submitError'));
		// 			this.loadingExcel = false;
		// 			this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
		// 				type: 'warning'
		// 				//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		// 			});
		// 		});
		// },
		//查询数据
		// serPageData() {
		// 	this.pagination.current_page = 1;
		// 	this.getPageData();
		// },
		handleSelectStatus(key, keyPath) {
		  // console.log(key, keyPath);
			this.filterData.status = key;
			this.initData();
		},
		//商品类目变化
		// CateChang2(v) {
		// 	// this.onChangeNoSave(true);

		// 	// this.CateValue = v;

		// 	this.filterData.subjectName = this.CateValue[0];
		// 	this.serPageData();
		// 	// this.baseInfo.commodityCategories.first = this.CateValue[0];
		// 	// this.baseInfo.commodityCategories.second = this.CateValue[1];
		// 	// this.baseInfo.commodityCategories.third = this.CateValue[2];

		// 	//该类目商品基本属性
		// 	// this.getCommodityAttribute();
		// },
		//分页的筛选项数据
		pageFilterData() {
			return {
				proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
				status: this.filterData.status ? this.filterData.status : null,
				whNo: this.filterData.whNo ? this.filterData.whNo : null,
				// subjectName: this.filterData.subjectName ? this.filterData.subjectName : null,
				goodsSku: this.filterData.goodsSku ? this.filterData.goodsSku : null,
				// hashCode: this.filterData.hashCode ? this.filterData.hashCode : null,
				// goodsName: this.filterData.goodsName ? this.filterData.goodsName : null,
				// goodsNameEn: this.filterData.goodsNameEn ? this.filterData.goodsNameEn : null,
				keyword: this.filterData.keyword ? this.filterData.keyword : null
				// declareNameCh: this.filterData.declareNameCh ? this.filterData.declareNameCh : null,
			};
		},
		//请求分页数据
		getPageData() {
			let filterData = Object.assign(
				{
					offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
					limit: this.pagination.page_size //当前页显示数目
				},
				this.pageFilterData()
			);

			// let _this = this;
			this.loading_load = true;
			this.$http
				.put(this.$urlConfig.WhSkuSplitPageList, filterData)
				.then(({ data }) => {
					console.log(this.$store.getters.getUserInfo);
					console.log('分页，请求成功');
					console.log(data);
					
					this.loading_load = false;
					//表格显示数据
					this.tableData = data.rows;
					//当前数据总条数
					this.pagination.total = parseInt(data.total);
					//当前页数
					// this.pagination.current_page = parseInt(data.current);
					//当前页条数
					// this.pagination.page_size = parseInt(data.size);
				})
				.catch(error => {
					console.log(error);
					console.log('分页，请求失败');
					this.loading_load = false;
				});
		},
		//明细
		getDetList(breakId) {
			this.loading = true;
			let HttpType = {};
			HttpType = this.$http.get(this.$urlConfig.WhSkuSplitDetList + '/' + breakId, {});
			HttpType.then(({ data }) => {
				console.log(data);
				this.loading = false;
				if (200 == data.code) {
					this.form.recordLists = data.rows;
				} else {
					this.$alert(data.msg ? data.msg : this.$t('tips.queryErrorFailed'), this.$t('tips.tipsTitle'), {
						type: 'warning'
					});
				}
			}).catch(error => {
				console.log(error);
				console.log(this.$t('tips.submitError'));
				this.loading = false;
				this.$alert(this.$t('tips.queryRequestFailed'), this.$t('tips.tipsTitle'), {
					type: 'warning'
				});
			});
		},
		//操作
		// sureBillAction(row,msg,state) {
		//   console.log(this.$t('i18nn_4f5bb4ff8b3d804b'), row);
		//   console.log(row);
		//   this.$confirm(this.$t('i18nn_e8ec92802315a287')+msg+'通过该笔账单?', this.$t('tips.tipsTitle'), {
		//     //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		//     //cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
		//     type: 'warning'
		//   }).then(() => {
		//     //确认
		//     let dataParm = {
		//       ids:[row.id],
		//       state:state,
		//     }
		//     this.updatePageData(dataParm, msg);
		//   }).catch(() => {

		//   });

		// },
		//更新特定数据,
		// updatePageData(dataParm, msg) {
		//   // let _this = this;
		//   this.loading_load = true;
		//   //浅拷贝、对象属性的合并
		//   let dataParmAss = Object.assign({}, dataParm);
		//   // dataParmAss.isDefault = 1;
		//   this.$http.post(this.$urlConfig.PartnerMatchingFeeModifyPage, dataParmAss)
		//     .then(({ data }) => {
		//       console.log(msg + "特定数据，请求成功");
		//       console.log(data);
		//       if (200 == data.code) {
		//         this.$message.success(msg + '成功！');
		//         this.getPageData();
		//       } else {
		//         this.$message.warning(data.msg ? data.msg : msg + '失败,请重试');
		//       }
		//     })
		//     .catch((error) => {
		//       console.log(error);
		//       console.log("更新特定数据，请求失败");
		//       this.loading_load = false;
		//       this.$message.warning('' + msg + '失败,请重试！');
		//     });
		// },
		//选择数据后回调
		selRow(event, row) {
			event.stopPropagation();
			this.$emit('selectRow', row);
		},
		//查询数据字典
		// getDicData() {
		// 	// let _this = this;
		// 	// console.log(keyword);

		// 	// this.loading_load = true;
		// 	this.$http
		// 		.put(this.$urlConfig.HyDicQueryList, ['wh_no'])
		// 		.then(({ data }) => {
		// 			console.log('查询数据字典，请求成功');
		// 			console.log(data);
		// 			if (200 == data.code && data.data) {
		// 				this.selectOption.wh_no = data.data['wh_no'];
		// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
		// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
		// 				// this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
		// 			} else {
		// 				if (!data.msg) {
		// 					data.msg = this.$t('tips.submitError');
		// 				}
		// 				this.$message.warning(data.msg);
		// 			}
		// 		})
		// 		.catch(error => {
		// 			console.log(error);
		// 			console.log('查询数据字典接口，请求失败');
		// 			this.$message.error(this.$t('tips.submitRequestError'));
		// 		});
		// }
	}
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
// /deep/ .el-input-number,
// .el-select {
// 	// width: 100px;
// 	.el-input__inner {
// 		text-align: left;
// 	}
// }
// .form_msg {
// 	color: #e6a23c;
// }
</style>
